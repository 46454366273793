function getInformationFromPin(pincode){
    $.ajax({
        type:"GET",
        url:"https://api.postalpincode.in/pincode/"+pincode,
        success:function(data){
            console.log(data);
            po = data[0]['PostOffice'][0]
            console.log(data[0]['PostOffice'][0]["Block"]);
            $("#visitor_city").val(po["Block"])
            $("#visitor_state").val(po["State"])
        }
    })
}
$(document).ready(function(){
    $("#visitor_state").attr("disabled","disabled")
    $("#visitor_city").attr("disabled","disabled")
    $("#visitor_pincode").on('blur',function(e){

        getInformationFromPin($(e.currentTarget).val());
    })
})