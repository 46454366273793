// async function  startCamera(video){
//     let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
//     video.srcObject = stream;
// 
// }
// 
// $(document).ready(function(){
//     // $('table').dataTable();
//     startCamera(document.querySelector("#videoElement"))
//     $("#capture-image").on('click', function() {
//         let video = document.querySelector("#videoElement");
//         let canvas = document.querySelector("#canvas");
//         canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
//         let image_data_url =    canvas.toDataURL('image/jpeg');
//         $("#avatar").val(image_data_url)
// 
//         // data url of the image
//         console.log(image_data_url);
//     });
// 
// })